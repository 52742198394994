@import "../../node_modules/@syncfusion/ej2-react-grids/styles/bootstrap4.css";
@import '../../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap4.css';
@import '../../node_modules/@syncfusion/ej2-calendars/styles/bootstrap4.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap4.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap4.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/bootstrap4.css';
@import '../../node_modules/@syncfusion/ej2-icons/styles/bootstrap4.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap4.css';
@import '../../node_modules/@syncfusion/ej2-lists/styles/bootstrap4.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap4.css';
@import '../../node_modules/@syncfusion/ej2-richtexteditor/styles/bootstrap4.css';

div.legend>.card-header {
  color: #288054;
  font-weight: bold;
  font-size: 18px;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.react-datepicker-popper {
  z-index: 1313 !important
}

.custom-title-class>.card-header {
  font: Segoe UI, Helvetica, sans-serif !important;
  font-size: 24pt !important;
  font-weight: bold !important;
}

.step-card>.card-header {
  font: Segoe UI, Helvetica, sans-serif !important;
  font-size: 20pt !important;
  font-weight: bold !important;
}

.directory-header {
  background-color: whitesmoke;
}

div.outline {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 10px;
  margin: 5px;
}

.recharts-wrapper {
  margin: 0 auto;
}