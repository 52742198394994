body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Used on pages that will ultimately vertically scroll This prevents the dynamic resize 
from having issues after the content is added. */
.show-vertical-scroll {
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

label {
  font-weight: bold;
}

.additional-info {
  color: darkolivegreen;
}

div.error {
  color: red;
}

.info-cards {
  min-height: 125px;
}

.condensed-info-cards {
  min-height: 200px;
}

.ms-TooltipHost:hover {
  color: #00bc8c;
  cursor: pointer;
  text-decoration: underline;
}

.ms-Persona:hover {
  cursor: pointer;
}